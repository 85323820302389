import { useRef, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import InboxTwoToneIcon from "@material-ui/icons/InboxTwoTone";
import { experimentalStyled } from "@material-ui/core/styles";
import ExpandMoreTwoToneIcon from "@material-ui/icons/ExpandMoreTwoTone";
import AccountBoxTwoToneIcon from "@material-ui/icons/AccountBoxTwoTone";
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import PasswordIcon from "@material-ui/icons/Password";
import SettingsSuggestIcon from "@material-ui/icons/SettingsSuggest";
import BusinessIcon from "@material-ui/icons/Business";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import axios from "src/utils/axios";
import { buildParameterForm } from "src/utils/formUrlencoded";
import AlertDialog from "src/components/AlertDialog";

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { t } = useTranslation();

  const history = useHistory();

  const { user, logout } = useAuth();

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [isOpenSend, setOpenSend] = useState(false);
  const [isOpenSendEnd, setOpenSendEnd] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
    }
  };

  const handleProfile = async () => {
    try {
      handleClose();
      if (user?.isAdmin) {
        history.push("/admin/update");
      } else {
        history.push("/profile/update");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleAdminPanel = async () => {
    try {
      handleClose();
      history.push("/admin/users/list");
    } catch (err) {
      console.error(err);
    }
  };

  const handleAdminOverview = async () => {
    try {
      handleClose();
      if (user?.isAdmin) {
        history.push("/admin/crypto");
      } else {
        history.push("/preview/dashboards/crypto");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleOverview = async () => {
    try {
      handleClose();
      if (user?.isAdmin) {
        history.push("/admin/crypto");
      } else {
        history.push("/preview/dashboards/crypto");
      }
    } catch (err) {
      console.error(err);
    }
  };
  const handleUpdatePassword = async () => {
    try {
      handleClose();
      if (user?.isAdmin) {
        history.push("/admin/update-password");
      } else {
        history.push("/profile/update-password");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSetOpenSendEnd = ()=>{
    setOpenSendEnd(false);
    handleClose();
  }
  
  const handleSendMsgToActivate = async () => {

    try {
      //handleClose();
      const result = await axios.post(
        "api/auth/activate-user",
        buildParameterForm({
          email: user.email
        })
      );
      
      setOpenSendEnd(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.lastName}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.lastName}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>

        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav"></List>
        <Divider />
        <Box sx={{ m: 1 }} justifyContent="flex-start">
          <Button
            color="primary"
            fullWidth
            onClick={
              () => handleOverview()
              //   handleAdminOverview(user?.isAdmin)
            }
          >
            <AccountBalanceWalletTwoToneIcon sx={{ mr: 1 }} />
            &#160; &#160;&#160; &#160;{t("Overview")} &#160; &#160;&#160;&#160;
            &#160;
          </Button>
        </Box>
        <Divider />
        {user?.isAdmin ? (
          <Box sx={{ m: 1 }} justifyContent="flex-start">
            <Button color="primary" fullWidth onClick={handleAdminPanel}>
              <BusinessIcon sx={{ mr: 1 }} />
              &#160; &#160;{t("Admin panel")} &#160; &#160;&#160;
            </Button>
          </Box>
        ) : (
          ""
        )}
        <Box sx={{ m: 1 }} justifyContent="flex-start">
          <Button color="primary" fullWidth onClick={handleProfile}>
            <SettingsSuggestIcon sx={{ mr: 1 }} />
            &#160; &#160; {t("User Settings")} &#160; &#160;
          </Button>
        </Box>
        <Divider />
        <Box sx={{ m: 1 }} justifyContent="flex-start">
          <Button color="primary" fullWidth onClick={handleUpdatePassword}>
            <PasswordIcon sx={{ mr: 1 }} />
            {t("Change password")}
          </Button>
        </Box>
        {!user.isActive ?
          <Box sx={{ m: 1 }} justifyContent="flex-start">
            <Button color="primary" fullWidth onClick={() => setOpenSend(true)}>
              {t("Send a message to activate")}
            </Button>
          </Box> : <></>}
        <Box sx={{ m: 1 }} justifyContent="flex-start">
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            &#160; &#160;&#160;&#160;&#160; &#160;{t("Sign out")} &#160;
            &#160;&#160;&#160;&#160;&#160;
          </Button>
        </Box>
        <AlertDialog
          open={isOpenSend}
          setOpen={setOpenSend}
          onRemoveHandle={handleSendMsgToActivate}
          title={
            "Should we resend a confirmation email to the user?"
          }
        />
        <AlertDialog
          open={isOpenSendEnd}
          setOpen={handleSetOpenSendEnd}
          showOk={true}
          onRemoveHandle={()=>{}}
          title={
            "The confirmation email has been resent to the user!"
          }
        />
      </Popover>
    </>
  );
}

export default HeaderUserbox;
