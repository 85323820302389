import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Typography, Grid } from "@material-ui/core";

export default function ContextDialog({
  open,
  setOpen,
  onRemoveHandle,
  title,
  children,
}) {
  const handleClose = () => {
    setIsEnabled(false);
    setOpen(false);
  };

  const handleRemove = () => {
    onRemoveHandle();
    setIsEnabled(false);
    setOpen(false);
  };

  const scrollEl = React.useRef(null);

  const [isEnabled, setIsEnabled] = React.useState(false);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"md"}
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              ml: -1,
            }}
          >
            <Grid
              container
              spacing={4}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                color: "#FFFFFF",
                flexDirection: "column",
              }}
            >
              <Grid item>
                <Typography color="textPrimary" variant="h1">
                  {/*title*/}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent
          onScroll={(val) => {
            if (
              scrollEl.current.scrollHeight - scrollEl.current.scrollTop <
              1500
            ) {
              setIsEnabled(true);
            }
          }}
          ref={scrollEl}
        >
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleRemove}
            disabled={!isEnabled}
          >
            SUBMIT
          </Button>
          <Button color="primary" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
