//export const buildParameterForm = (details) => {
//  var formBody = [];
//  for (var property in details) {
//    var encodedKey = encodeURIComponent(property);
//    var encodedValue = encodeURIComponent(details[property]);
//    formBody.push(encodedKey + "=" + encodedValue);
//  }
//  return formBody.join("&");
//};

export const buildParameterForm = (details, acceptedFiles) => {
  var formData = new FormData();
  for (var property in details) {
  //  if (details[property]) {
      formData.append(property, details[property]);
  //  }
  }
  if (acceptedFiles)
    for (const file of acceptedFiles) {
      formData.append("filedata", file, file.name);
    }
  return formData;
};
