import { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";
import SuspenseLoader from "src/components/SuspenseLoader";
import axios from "src/utils/axios";
import { buildParameterForm } from "src/utils/formUrlencoded";
import { useCookies } from "react-cookie";

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  captcha: null,
};

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

const setSession = (accessToken, setCookie, removeCookie) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `bearer ${accessToken}`;
    setCookie("accessToken", accessToken, { path: "/" });
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case "CAPTCHA": {
      const { captcha } = action.payload;

      return {
        ...state,
        captcha,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: "JWT",
  login: () => Promise.resolve(),
  logout: () => {},
  userUpdate: () => {},
  register: () => Promise.resolve(),
  setCaptcha: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);

  const login = async (email, password) => {
    const emailForm = encodeURIComponent(email);
    const passwordForm = encodeURIComponent(password);
    const formData = `email=${emailForm}&password=${passwordForm}`;
    const response = await axios.post("api/auth/login", formData);
    const { token, user } = response.data;

    const _user = {
      ...response.data,
      avatar: `${process.env.REACT_APP_URL_SERVER}${response.data?.avatar}`,
    };

    setSession(token, setCookie, removeCookie);
    dispatch({
      type: "LOGIN",
      payload: {
        user: _user,
      },
    });
  };

  const userUpdate = (user) => {
    dispatch({
      type: "LOGIN",
      payload: {
        user,
      },
    });
  };

  const logout = () => {
    setSession(null, setCookie, removeCookie);
    dispatch({ type: "LOGOUT" });
  };

  const setCaptcha = (captcha) => {
    axios.defaults.headers.common["g-recaptcha-response"] = captcha;
 
    dispatch({
      type: "CAPTCHA",
      payload: {
        captcha,
      },
    });
  };

  const register = async (values) => {
    const response = await axios.post(
      "api/auth/signup",
      buildParameterForm({
        ...values,
        bank_account: parseInt(values["bank_account"]) || "",
      })
    );
    const { token, user } = response.data;

    window.localStorage.setItem("accessToken", token);

    dispatch({
      type: "REGISTER",
      payload: {
        user,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken) {
          setSession(accessToken, setCookie, removeCookie);

          const response = await axios.get("api/api/user/data");
          const user = {
            ...response.data,
            avatar: `${process.env.REACT_APP_URL_SERVER}${response.data?.avatar}`,
          };

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        userUpdate,
        setCaptcha,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
