import "./terms.conditions.css";

function LoginCover() {
  return (
    <div class="div-body">
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0-center">
        <span class="c1">
          <strong>TERMS &amp; CONDITIONS</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0-center">
        <span class="c2">
          <strong>Right Of The Dot, LLC</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0-center">
        <span class="c2">
          <i>
            Effective Date: September 19, 2024{ /*new Date().toDateString()*/}
          </i>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong>You are agreeing to the terms below.</strong> By registering
          to bid and/or by bidding at auction you agree to these terms, so you
          should read them carefully before doing so. All terms stated herein,
          whether in the body of these Terms & Conditions or in the attached
          Appendices, shall apply to all persons or entities that participate in
          an auction or other proceeding administered by Right of the Dot, LLC
          (“ROTD”). These Terms & Conditions shall prevail over any other
          written or oral terms unless there is a written, later-dated addendum
          signed by a ROTD officer and at least one other party stating ROTD’s
          intent that these Terms & Conditions shall be superseded and setting
          forth the specific manner in which they are superseded.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          You will find a glossary explaining the meaning of certain words and
          expressions used in this Terms & Conditions. In addition to these
          Terms & Conditions, which govern all transactions with ROTD, Lots in
          which we offer Non-Fungible Tokens are governed by the Additional
          Conditions of Sale – Non-Fungible Tokens, which are available in
          Appendix A herein. For the sale of Non-Fungible Tokens, to the extent
          there is a conflict between these Terms & Conditions and the
          “Additional Conditions of Sale – Non-Fungible Tokens,” the latter
          controls. Unless we own a Lot in whole or in part, ROTD acts as agent
          for the Seller.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 1. &nbsp; BEFORE THE SALE</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          a. &nbsp;
          <strong>
            DESCRIPTIONS OF LOTS; DISCLAIMER OF WARRANTIES; REPRESENTATIONS
          </strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        i.&nbsp;
        <span class="c1">
          Our description of any Lot in the catalogue, any condition report, and
          any other statement made by us (whether orally or in writing) about
          any Lot, including about its nature or condition, scope, ownership,
          provenance, technical details, non-fungible token (“NFT”) metadata,
          security or integrity are our opinion and not to be relied upon as a
          statement of fact. We do not carry out in-depth research of the sort
          carried out by professional researchers and scholars. All statements
          of dimensions are approximate only.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        ii.&nbsp;
        <span class="c1">
          We do not provide any guarantee in relation to the nature of a Lot of
          any sort. Bidders are advised to conduct their own research regarding
          the condition or nature of Lots.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        iii. &nbsp;
        <span class="c1">
          Lots are sold “as is,” in the condition they are in at the time of the
          sale, without any representation or warranty or assumption of
          liability of any kind as to condition by ROTD or by the Seller. The
          fact that a Lot may be stored on a blockchain should also not be
          interpreted as, or deemed to be, any guarantee of condition.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        iv.&nbsp;
        <span class="c1">
          Any reference to condition in a catalogue entry or in a condition
          report will not amount to a full description of condition, and images
          may not show a Lot clearly. Condition reports and Lot descriptions are
          not an alternative to examining a Lot in person or seeking your own
          professional advice. We take no responsibility for providing a
          condition report as to any Lot.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        v.&nbsp;
        <span class="c1">
          We are not responsible to you to give any representation, warranty, or
          guarantee, or assume any liability of any kind in respect of any Lot
          or digital asset. We cannot and do not represent, warrant, or
          guarantee that:
          <p class="c0 f-c1">
            1.&nbsp;
            <span class="c1">
              any Lot or digital asset is error-free and meets your
              requirements, or
            </span>
          </p>
          <p class="c0 f-c1">
            2.&nbsp;
            <span class="c1">
              that errors or defects in the Lot or digital asset can or will be
              corrected; or
            </span>
          </p>
          <p class="c0 f-c1">
            3.&nbsp;
            <span class="c1">
              the Lot or the delivery mechanism for the Lot is free of viruses
              or other harmful components. We cannot and do not represent,
              warrant, or guarantee the persistence, security, stability,
              integrity or continued existence of any digital asset.
            </span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        vi.&nbsp;
        <span class="c1">
          Estimates are based on the condition, rarity, quality, and provenance
          of the Lots and on prices recently paid at auction or brokerage for
          similar property. Estimates may also be based on appraisals of similar
          Lots. Estimates can change. Neither you, nor anyone else, may rely on
          any estimates as a prediction or guarantee of the actual selling price
          of a Lot or its value or for any other purpose. Estimates do not
          include a Buyer’s Premium, a commission, or any applicable taxes.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;
        <span class="c1">
          <strong> Withdrawal.</strong> ROTD may, in its discretion, withdraw
          any Lot from auction at any time prior to or during the sale of the
          Lot. ROTD has no liability to you for any decision to withdraw.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;
        <span class="c1">
          <strong> Exclusivity Period.</strong> Submission of a Lot for sale
          constitutes an agreement on behalf of Seller to grant ROTD an
          exclusive right to sell the Lot, with such exclusivity period running
          for 60 days from the end of the extended auction period.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 2. &nbsp;LIMITATION OF LIABILITY</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          a. &nbsp;We have no responsibility to any person other than a Buyer in
          connection with the purchase of any Lot.{" "}
          <strong>
            IN NO EVENT WILL THE AGGREGATE LIABILITY OF ROTD AND ROTD PARTNERS
            (JOINTLY), WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING
            NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), OR OTHER THEORY,
            ARISING OUT OF OR RELATING TO THESE TERMS OR THE OWNERSHIP OF THE
            LOTS, EXCEED THE BUYER’S PREMIUM AMOUNT YOU HAVE PAID TO ROTD FOR
            THE LOTS, NFTs OR DIGITAL ASSETS.{" "}
          </strong>
          We will not be responsible to you for any reason for any other damages
          or expenses.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          b. &nbsp;To the fullest extent permitted by applicable law, in no
          event will ROTD or any ROTD Partner be liable for:
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        i.&nbsp;
        <span class="c1">
          ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES
          OF ANY KIND (INCLUDING, BUT NOT LIMITED TO, WHERE RELATED TO LOSS OF
          REVENUE, INCOME OR PROFITS, LOSS OF USE OR DATA, OR DAMAGES FOR
          BUSINESS INTERRUPTION);
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        ii.&nbsp;
        <span class="c1">
          ANY DAMAGES ARISING FROM FAILURE TO TRANSFER THE LOT DUE TO SELLER OR
          BUYER ERROR SUCH AS FORGOTTEN PASSWORDS, MISTYPED ADDRESSES OR
          INCORRECTLY CONSTRUCTED TRANSACTIONS, INCORRECTLY PROGRAMMED NFTS,
          MINING ATTACKS, CYBERSECURITY ATTACKS, WEAKNESSES IN ROTD SECURITY,
          BLOCKCHAIN MALFUNCTIONS OR OTHER TECHNICAL ERRORS, TELECOMMUNICATIONS
          FAILURE, UNFAVORABLE REGULATORY DETERMINATIONS OR ACTIONS IN ONE OR
          MORE JURISDICTIONS (INCLUDING WITH RESPECT TO NFTS OR
          CRYPTOCURRENCIES), TAXATION OF NFTS OR CRYPTOCURRENCIES, PERSONAL
          INFORMATION DISCLOSURE, UNINSURED LOSSES, UNANTICIPATED RISKS,
          VOLATILITY RISKS, SERVER FAILURE OR DATA LOSS, CORRUPTED OR OTHERWISE
          INACCESSIBLE DIGITAL WALLETS, UNAUTHORISED ACCESS TO APPLICATIONS,
          INABILITY TO ACCESS OR TRANSFER THE NFT, INABILITY TO ACCESS OR
          DISPLAY THE DIGITAL ASSET, RISKS ARISING FROM THIRD-PARTY PROVIDERS,
          INCLUDING THIRD-PARTY PROVIDERS THAT MAY MINT THE NFTAND/OR STORE THE
          NFT OR THE DIGITAL ASSET; AND
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        iii. &nbsp;
        <span class="c1">
          ANY DAMAGES ARISING FROM ANY UNAUTHORIZED THIRD-PARTY ACTIVITIES,
          INCLUDING WITHOUT LIMITATION THE INTRODUCTION OF VIRUSES OR OTHER
          MALICIOUS CODE, THE USE OF PHISHING, SYBIL ATTACKS, 51% ATTACKS,
          BRUTEFORCING, CHANGES TO THE PROTOCOL RULES OF THE BLOCKCHAIN (I.E.,
          “FORKS”) OR OTHER MEANS OF ATTACK THAT AFFECT, IN ANY WAY, THE NFT OR
          DIGITAL ASSET.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;
        <span class="c1">
          THE LIMITATIONS SET FORTH IN THIS SECTION WILL NOT LIMIT OR EXCLUDE
          LIABILITY FOR FRAUD OR INTENTIONAL, WILLFUL MISCONDUCT OF ROTD BUT
          SHALL LIMIT CLAIMS FOR NEGLIGENCE OR GROSS NEGLIGENCE.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;
        <span class="c1">
          Some jurisdictions do not allow the exclusion of certain warranties,
          disclaimer of implied terms, or limitations or exclusions of liability
          for incidental or consequential damages in contracts with consumers.
          As a result, some or all of the exclusions of warranties, disclaimers,
          and limitations or exclusions of liability in this section may not
          apply to certain purchasers.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 3. &nbsp; REGISTERING TO BID</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          a. &nbsp;<strong> FIRST-TIME BIDDERS</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        i.&nbsp;
        <span class="c1">
          If this is your first time bidding at an ROTD auction (or if you have
          not bid at an ROTD auction within the past two years) you must
          register to bid at least 48 hours before an auction begins so that we
          have time to process and approve your registration.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        ii.&nbsp;
        <span class="c1">
          First-time Bidders will be required to provide the following
          information:
          <p class="c0 f-c1">
            1.&nbsp;
            <span class="c1">
              Individuals: Photo identification (valid driver’s license,
              national identity card, or passport) and, if not shown on the ID
              document, proof of your current address (for example, a current
              utility bill or bank statement);
            </span>
          </p>
          <p class="c0 f-c1">
            2.&nbsp;
            <span class="c1">
              Corporations and limited liability companies: Your Certificate of
              Incorporation or equivalent document(s) showing your name and
              registered address together with documentary proof of directors
              and beneficial owners; andts in the Lot or digital asset can or
              will be corrected; or
            </span>
          </p>
          <p class="c0 f-c1">
            3.&nbsp;
            <span class="c1">
              Trusts, partnerships, offshore companies, and other business
              structures: Please contact us in advance of the auction to discuss
              our documentation requirements.
            </span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        iii. &nbsp;
        <span class="c1">
          We may in our discretion require proof of financial capacity or a
          deposit before permitting you to bid.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          b. &nbsp;<strong> RETURNING BIDDERS</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        i.&nbsp;
        <span class="c1">
          We may at our discretion ask you for current identification, a
          financial reference, or a deposit as a condition of allowing you to
          bid.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          c. &nbsp;<strong> RESERVATION OF RIGHT TO DECLINE ANY BIDDER</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        i.&nbsp;
        <span class="c1">
          We may, in our sole discretion, decline to permit any party to bid,
          for reasons including but not limited to failure to demonstrate
          capacity to pay, failure to provide requested documentation, or
          failure to comply with anti-money laundering or know-your-customer
          requirements; and if you make a successful bid, we may cancel the
          contract for sale between you and the Seller.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong>4. &nbsp; BIDDING ON BEHALF OF ANOTHER PERSON</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        <span class="c1">
          a. &nbsp;A Bidder is permitted to bid as the agent of another person
          if the following conditions are met:
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        i.&nbsp;
        <span class="c1">
          The person on whose behalf bids will be made must complete the
          registration requirements set forth in Section 3 above.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        ii.&nbsp;
        <span class="c1">
          The Bidder must supply a signed letter authorizing the bidding on
          behalf of the third party.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        iii. &nbsp;
        <span class="c1">
          <strong>
            <u>
              By bidding for a third party, the Bidder warrants that he or she
              is accepting personal liability to pay the Purchase Price and all
              other sums due
            </u>
          </strong>{" "}
          unless it has been agreed in writing with ROTD, before commencement of
          the auction, that the Bidder is acting as an agent on behalf of a
          named third party acceptable to ROTD and that ROTD will only seek
          payment from the named third party.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c1">
        iv.&nbsp;
        <span class="c1">
          If ROTD determines after an auction is completed that a Bidder has
          entered bids on behalf of an undisclosed third party, it may in its
          sole discretion seek to hold that third party liable for all sums due,
          including by instituting an arbitration or other legal proceeding
          against that third party seeking any or all of the remedies to which
          ROTD may be entitled under this agreement. If ROTD has determined that
          a Bidder has entered bids on behalf of an undisclosed third party then
          said Bidder shall agree and warrant that there is privity of contract
          between said undisclosed third party and ROTD.{" "}
          <strong>
            {" "}
            <u>
              {" "}
              The decision to seek relief from an undisclosed third-party
              Bidder, however, shall not excuse the personal liability of the
              Bidder and said Bidder and the undisclosed third party shall be
              jointly and severally liable to ROTD for any damages resulting.{" "}
            </u>
          </strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*----------------------------------------------------------------------------- */}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 5. &nbsp; BIDDING PROCEDURES</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a. &nbsp;&nbsp;
        <span class="c1">
          All Bidders represent and warrant that an auction bid is a contractual
          offer which, if accepted, creates a contract to pay all sums due.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          &nbsp;All bidding is governed by these Terms & Conditions and any
          other applicable terms, statutes, or regulations.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;&nbsp;
        <span class="c1">
          Bidding in person: Any Bidder may bid at an auction in person, if such
          auction is conducted live. Bidders seeking to bid in person must
          register for a numbered bidding paddle at least 30 minutes before the
          auction. You may register online at{" "}
          <a href="https://rotd.com/">https://rotd.com/</a> or in person. For
          help, please contact us at +1 954-288-6810. Live auctions may be
          videotaped or otherwise recorded and a Bidder’s participation in a
          live auction constitutes acknowledgement and agreement that the
          Bidder’s person or voice may incidentally be captured on such
          recordings.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;&nbsp;
        <span class="c1">
          Bidding by phone: ROTD may, as appropriate and as logistics, staffing,
          and other circumstances permit, offer bidding by phone. Your request
          for this service must be made no later than 24 hours prior to the
          auction. ROTD will not provide translation or commentary services for
          any phone Bidder. Telephone bidding may be recorded by audio or other
          means and your request for, or participation in telephone bidding
          constitutes agreement to such recording.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e. &nbsp;
        <span class="c1">
          Bidding by Internet: For certain auctions we will accept bids over the
          Internet. Internet bidding will be logged.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;&nbsp;
        <span class="c1">
          Written Bids: ROTD will accept written bids. You can find a Written
          Bid Form at https://rotd.com, or by viewing the Lots online at the
          same address. We must receive your completed Written Bid at least 24
          hours before the auction. Bids must be placed in the allowable
          currencies of the auction at that time. The Auctioneer will take
          reasonable steps to carry out written bids at the lowest possible
          price, taking into account the reserve. If you make a written bid on a
          Lot which does not have a reserve and there is no higher bid than
          yours, we may bid on your behalf at around 50% of the low estimate or,
          if lower, the amount of your bid. If we receive written bids on a Lot
          for identical amounts, and at the auction these are the highest bids
          on the Lot, we will sell the Lot to the Bidder whose written bid we
          received first.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        g. &nbsp;&nbsp;
        <span class="c1">
          These bidding services set forth above are a free service offered as a
          convenience to our clients and ROTD is not liable for any error (human
          or otherwise), omission, or breakdown in providing these services.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 6. &nbsp; CONDUCTING THE SALE</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;
        <span class="c1">
          We may, at our option, refuse admission to our premises, decline to
          permit participation in any auction, or reject any bid.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;
        <span class="c1">
          Unless otherwise indicated, all Lots are subject to a reserve. We
          identify Lots that are offered without reserve with a symbol next to
          the Lot number. Opening bids on No Reserve Lots may vary and start at
          US$300.00 and up.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;
        <span class="c1">
          A Seller may not raise the reserve price of a Lot after submission of
          the Lot for sale. A seller may decrease the reserve price of a Lot
          before or during an auction.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;
        <span class="c1">
          The Auctioneer and/or ROTD can at his or her sole option:
          <p class="c0 f-c1">
            i.&nbsp;
            <span class="c1">refuse any bid;</span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c1">
            ii.&nbsp;
            <span class="c1">
              move the bidding backwards or forwards in any way we may decide,
              or change the order of the Lots;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c1">
            iii.&nbsp;
            <span class="c1">withdraw any Lot;</span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c1">
            iv.&nbsp;
            <span class="c1">
              divide any Lot or combine any two or more Lots;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c1">
            v.&nbsp;
            <span class="c1">
              reopen or continue the bidding even after the hammer has fallen;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c1">
            vi.&nbsp;
            <span class="c1">extend the auction period; or</span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c1">
            vii.&nbsp;
            <span class="c1">
              in the case of error or dispute related to bidding and whether
              during or after the auction, continue the bidding, determine the
              successful Bidder, cancel the sale of the Lot, or reoffer and
              resell any Lot.
            </span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e.&nbsp;
        <span class="c1">
          <strong>
            <u>
              If you believe that the Auctioneer has accepted a successful bid
              in error, you must provide a written notice detailing your claim
              within 3 business days of the date of the auction.{" "}
            </u>
          </strong>
          The Auctioneer and/or ROTD will consider such a claim in good faith.
          If the Auctioneer and/or ROTD, in the exercise of our discretion,
          decides after the auction is complete to cancel the sale of a Lot, or
          reoffer and resell a Lot, we will notify the successful Bidder no
          later than by the end of the seventh business day following the date
          of the auction. The Auctioneer’s and/or ROTD decision in exercise of
          this discretion is final. This does not in any way prejudice ROTD’s
          ability to cancel the sale of a Lot under any other applicable
          provision of these Terms & Conditions, including the rights of
          cancellation set forth herein.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;
        <span class="c1">
          ROTD may pick any Bidder as the high Bidder as ROTD deems fit or
          necessary, in its sole discretion, notwithstanding that a Bidder
          received a notice that it had been outbid. Such notices do not
          necessarily constitute a final determination of the winning bid.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        g.&nbsp;
        <span class="c1">
          The Auctioneer and/or ROTD may and is permitted to, at their sole
          option, bid on behalf of the Seller up to but not including the amount
          of the reserve either by making consecutive bids or by making bids in
          response to other Bidders. The Auctioneer and/or ROTD will not
          identify these as bids made on behalf of the Seller and will not make
          any bid on behalf of the Seller at or above the reserve. If Lots are
          offered without reserve, the Auctioneer and/or ROTD will generally
          decide to open the bidding at 50% of the estimate for the Lot. If no
          bid is made at that level, the Auctioneer and/or ROTD may decide to go
          backwards at his or her sole option until a bid is made, and then
          continue up from that amount. If there are no bids on a Lot, the
          Auctioneer and/or ROTD may deem such Lot unsold.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        h.&nbsp;
        <span class="c1">
          Bidding generally starts below the reserve and increases in steps (bid
          increments). The Auctioneer and/or ROTD will decide at their sole
          option where the bidding should start and the bid increments.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        i.&nbsp;
        <span class="c1">
          The auction room and online screens may show bids in some other major
          currencies as well as US dollars. Any conversion is for guidance only
          and we cannot be bound by any rate of exchange used including but not
          limited to crypto currencies. ROTD is not responsible for any error
          (human or otherwise), omission, or breakdown in providing these
          services.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        j.&nbsp;
        <span class="c1">
          Unless the Auctioneer and/or ROTD decides to use their discretion,
          when the Auctioneer’s hammer strikes or calls a Lot “Sold,” we have
          accepted the last bid. We may accept a previously rejected bid as the
          final bid even if the Bidder has received a notice stating that its
          bid was not accepted or that it has been outbid.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        k.&nbsp;
        <span class="c1">
          <strong> SHILL BIDDING:</strong>
          <p class="c0 f-c">
            i.&nbsp;
            <span class="c1">
              Shill bidding is an action, scheme, or agreement between a Bidder
              and a Seller at an auction to place bids on the Seller’s own Lot,
              with the intention of artificially inflating the auction price.
              Shill bidding is illegal under Florida law and is a violation of
              these Terms & Conditions. .{" "}
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;
            <span class="c1">
              If ROTD has good cause to believe any Bidder and Seller are
              involved in a scheme to shill bid, it may in its sole discretion
              take the following actions:
              <p class="c0 f-c1">
                1.&nbsp;
                <span class="c1">Cancel the sale of a Lot;</span>
              </p>
              <p class="c0 f-c1">
                2.&nbsp;
                <span class="c1">
                  Bar the Bidder and Seller from current and future ROTD
                  auctions; and
                </span>
              </p>
              <p class="c0 f-c1">
                3.&nbsp;
                <span class="c1">
                  Bring an action under this agreement against both or either
                  the Bidder or Seller to recover its damages, including
                  returned, unpaid, or lost commissions, Buyer’s Premiums,
                  reasonable attorney fees and costs, and interest thereon as
                  set forth in this agreement. If the arbitrator finds that
                  Bidder or a third party associated with the Bidder has
                  intentionally and maliciously participated in shill bidding,
                  then the arbitrator shall award treble damages.
                </span>
              </p>
            </span>
            <p class="c0 c2">
              <span class="c1"></span>
            </p>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;
            <span class="c1">
              ROTD’s remedies for shill bidding are in addition to, and
              exclusive of, any remedy it or any other Bidder or Buyer damaged
              by shill bidding may have at law or equity, in any arbitral or
              judicial forum of competent jurisdiction. .{" "}
            </span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      <p class="c0">
        <span class="c1">
          {" "}
          <strong> 7.&nbsp; AFTER THE AUCTION </strong>
        </span>{" "}
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;{" "}
        <span>
          {" "}
          ROTD will issue an invoice to the Buyer within three calendar days of
          the completed auction setting forth all sums due including the Buyer’s
          Premium. The invoice will be issued to the registered Bidder and the
          registered Bidder will be responsible for payment of all amounts due
          within seven calendar days of the date of the auction, unless prior
          arrangement has been made in writing for a longer payment term or
          payment by another party.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;{" "}
        <span>
          {" "}
          All sales shall include a Buyer’s Premium, which shall be a fixed
          percentage of the Final Sales Price (or “Hammer Price”) to recoup
          costs of marketing and auction, and which shall be paid by the Buyer.
          The Buyer’s Premium will vary from auction to auction and will be
          disclosed at ROTD.com in writing for each auction and/or Lot.{" "}
        </span>
      </p>{" "}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;{" "}
        <span>
          {" "}
          The Seller agrees that it is responsible for ROTD’s broker commission
          and agrees that all Buyer payments shall be made to ROTD, which shall
          hold payments in escrow; take its commissions, Buyer’s Premium, and
          any applicable taxes from Buyer’s payments; and then release the net
          proceeds to Seller upon satisfactory transfer of the Lot from ROTD’s
          or Seller’s control to Buyer’s;{" "}
          <strong> notwithstanding which</strong>, in any case in which ROTD has
          good cause to believe that shill bidding has occurred as set forth
          above, the broker commission (as well as any Buyer’s Premium) may be
          recoverable from both the Seller and the third-party Bidder, or
          either, at ROTD’s sole discretion.{" "}
        </span>
      </p>{" "}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;{" "}
        <span>
          {" "}
          Buyer agrees to make all payments for any successful bid solely to
          ROTD within seven days of completion of the auction.{" "}
        </span>
      </p>{" "}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e.&nbsp;{" "}
        <span>
          {" "}
          It shall be a breach of this agreement for Buyer and Seller to conduct
          their own escrow or make or accept payments for successful bids
          directly to or from each other.{" "}
        </span>
      </p>{" "}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;{" "}
        <span>
          {" "}
          Buyer and Seller both agree and warrant that they are solely
          responsible for payment of their own taxes, whether sales and use,
          income or otherwise.{" "}
        </span>
      </p>{" "}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        g.&nbsp;{" "}
        <span>
          {" "}
          Ownership of a Lot will not pass to Buyer until full payment for a
          successful bid has been made to ROTD in the form prescribed and the
          subtraction of such sums as are necessary to pay ROTD’s commission,
          Buyer’s Premium, taxes, or any other required payments or
          disbursements have been made.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*----------------------------------------------------------------------------- */}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 8. &nbsp; ROTD BROKER COMMISSION: HOW CALCULATED </strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          ROTD charges a commission for its services of up to 25 percent of the
          Final Sales Price of a Lot, otherwise known as the “Hammer Price.”
          This commission is separate from any Buyer’s Premium, and (except as
          otherwise set forth in this agreement) is payable to ROTD from the
          Buyer’s payment.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          This broker commission is calculated in the following manner: 25
          percent of the total sale price (or “Hammer Price”) of a Lot; unless
          ROTD has accepted a final bid in an amount less than 133 percent of
          the reserve price of the Lot, in which case ROTD’s broker commission
          shall be the difference between the reserve price and the total sale
          price (or “Hammer Price”) of the Lot.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*----------------------------------------------------------------------------- */}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 9. &nbsp; WARRANTIES</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          If we reasonably believe that completing the transaction is, or may be
          illegal; or that the Buyer or the Seller have breached any of the
          below warranties; or that the sale places us or any of the parties to
          the transaction under any liability; or may damage our reputation; or
          is the product of shill bidding, then we may, in our sole discretion,
          cancel or modify the transaction to avoid any of the aforesaid.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          <strong> SELLER’S WARRANTIES.</strong> For each Lot, the Seller gives
          a warranty that the Seller:
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;
            <span class="c1">
              is the owner of the Lot or a joint owner of the Lot acting with
              the permission of the other co-owners or, if the Seller is not the
              owner or a joint owner of the Lot, has the permission of the owner
              to sell the Lot or the right to do so by law; and
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;
            <span class="c1">
              has the right and ability to transfer ownership of the Lot to the
              Buyer without any restrictions or claims by anyone else, subject
              to any ongoing NFT payment that may be included in an NFT.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              If either of the above warranties are incorrect, the Seller shall
              be responsible for any damages associated with the breach
              including but not limited to lost or unpaid broker commissions,
              Buyer’s Premiums, interest as set forth in this agreement, and
              reasonable attorney fees and costs, as well as any amount required
              to be paid to place all non-breaching parties in the position they
              would have been but for the breach.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;&nbsp;
        <span class="c1">
          <strong> BUYER’S WARRANTIES.</strong> Buyer gives a warranty that the
          Buyer:
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;&nbsp;
            <span class="c1">
              is not acting as the agent of any third party except as has been
              disclosed to ROTD according to the terms of this agreement;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;&nbsp;
            <span class="c1">
              does not know nor has any reason to suspect that the third party
              on whose behalf Buyer is acting is not currently under
              investigation or indictment for, or has previously been convicted
              of, money laundering, tax evasion, or terrorist activities;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              does not know and has no reason to suspect that the funds used for
              settlement are connected with any criminal activity, including tax
              evasion;{" "}
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iv.&nbsp;&nbsp;
            <span class="c1">
              is not currently under investigation or indictment for, or has
              previously been convicted of, money laundering, tax evasion, or
              terrorist activities;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            v.&nbsp;&nbsp;
            <span class="c1">
              has conducted appropriate customer due diligence on Lots you are
              bidding on, including misspellings, TLDs and extensions, trademark
              filing and usage, and ownership of the Lot; and
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            vi.&nbsp;&nbsp;
            <span class="c1">
              has not entered into any arrangement or agreement with any party
              to engage in bidding in the furtherance of any crime including but
              not limited to shill bidding or tax evasion.{" "}
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;&nbsp;
        <span class="c1">
          <strong> NO IMPLIED WARRANTIES. </strong>ROTD DOES NOT MAKE ANY
          WARRANTYS OF ANY SORT, EXPRESS OR IMPLIED, ORAL OR WRITTEN, WITH
          RESPECT TO THE LOT, INCLUDING THE IMPLIED WARRANTIES OF
          MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, EACH OF WHICH IS
          SPECIFICALLY DISCLAIMED. WE DO NOT GIVE ANY REPRESENTATIONS,
          WARRANTIES, OR GUARANTIES OF ANY SORT.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*----------------------------------------------------------------------------- */}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 10. &nbsp; PAYMENT</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a. &nbsp; &nbsp;
        <span class="c1">
          HOW TO PAY
          <p class="c0 f-c">
            i.&nbsp;
            <span class="c1">
              Except as otherwise set forth in this agreement or by written
              agreement with ROTD, within seven calendar days of the auction
              (the “due date”), you must pay the “Purchase Price,” which is the
              sum of:
              <p class="c0 f-c1">
                1.&nbsp;
                <span class="c1">the Hammer Price; and</span>
              </p>
              <p class="c0 f-c1">
                2.&nbsp;
                <span class="c1">the Buyer’s Premium; and</span>
              </p>
              <p class="c0 f-c1">
                3.&nbsp;
                <span class="c1">
                  any applicable duties, goods, sales, use, compensating or
                  service tax, or VAT if applicable.{" "}
                </span>
              </p>
            </span>
            <p class="c0 c2">
              <span class="c1"></span>
            </p>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          We will only accept payment from the registered bidder, unless prior
          arrangement to accept payment from a third party has been made.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;&nbsp;
        <span class="c1">
          Payment must be made timely even if Buyer is seeking an export
          license.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;&nbsp;
        <span class="c1">
          Buyer must pay for Lots in the United States in U.S. dollars unless
          otherwise indicated in the Lot description. Payment can be made
          through the following means:
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;&nbsp;
            <span class="c1">
              Wire transfer; see wire information provided at ROTD.com or on
              Buyer’s invoice;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;&nbsp;
            <span class="c1">
              Credit card; see payments portal at ROTD.com. Please note that if
              you pay for your purchase using a credit card issued outside the
              region of the sale, depending on the type of card and account you
              hold, the payment may incur a cross-border transaction fee. If you
              think this may apply to you, please check with your card supplier
              before proceeding to check-out. ROTD has no responsibility for
              transaction fees you may incur and will not reduce the Purchase
              Price to account for such fees;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              Cash (including money orders and traveler’s checks), subject to a
              maximum global aggregate of US$7,500 per Buyer;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iv.&nbsp;&nbsp;
            <span class="c1">
              Bank checks made payable to Right Of The Dot, LLC and drawn in
              U.S. dollars from a U.S. bank, but property cannot be released
              until five business days have passed from submission of a bank
              check; or
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            v.&nbsp;&nbsp;
            <span class="c1">
              Crypto currencies via our crypto banking option found at ROTD.com.
              Any payment made in cryptocurrency must be made within 24 hours of
              the issuance of the invoice and as set forth herein. After the
              expiry of the 24-hour window, you may only make the payment in the
              currency of the sale.
              <p class="c0 f-c">
                1.&nbsp;&nbsp;
                <span class="c1">
                  The cryptocurrency amount will be calculated by us based on
                  the most recent published CME CF Ether-Dollar Reference Rate
                  (BRR and ETHUSD_RR) index rate as determined by us at the time
                  the invoice is issued, and will be disclosed in the invoice.
                  The amount of cryptocurrency specified in the invoice is the
                  amount of cryptocurrency that must be paid to us if that is
                  the payment option you select regardless of whether the
                  conversion rate at the time of auction or when you pay the
                  invoice or at any other time is different.
                </span>
              </p>
              <p class="c0 c2">
                <span class="c1"></span>
              </p>
              <p class="c0 f-c">
                2.&nbsp;&nbsp;
                <span class="c1">
                  You must send payment from a digital wallet maintained by an
                  exchange pre- approved by ROTD. You understand that You are
                  responsible for all gas and conversion fees associated with
                  any crypto payments.
                </span>
              </p>
              <p class="c0 c2">
                <span class="c1"></span>
              </p>
              <p class="c0 f-c">
                3.&nbsp;&nbsp;
                <span class="c1">
                  You must be the owner of the digital wallet, or, if you
                  registered a bid as a company, then the company must be the
                  owner. You agree, upon our request, to provide documentation
                  confirming that the cryptocurrency payment was made from a
                  digital wallet owned by you and maintained by one of the
                  providers listed above. “Ownership” of a digital wallet means
                  either you hold or have held on your behalf, or the company on
                  whose behalf you are bidding holds, or has held on its behalf,
                  the private keys associated with that digital wallet or that
                  the provider of the digital wallet recognizes you or the
                  company on whose behalf you are bidding as owning the
                  cryptocurrency associated with that digital wallet.
                </span>
              </p>
              <p class="c0 c2">
                <span class="c1"></span>
              </p>
              <p class="c0 f-c">
                4.&nbsp;&nbsp;
                <span class="c1">
                  Partial payments of a Lot from multiple digital wallets will
                  not be allowed. If you purchase multiple Lots, you may
                  purchase one Lot with payment from one digital wallet and
                  another Lot with payment from a different digital wallet but
                  this must be accomplished through two separate transactions.
                </span>
              </p>
              <p class="c0 c2">
                <span class="c1"></span>
              </p>
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e.&nbsp;&nbsp;
        <span class="c1">
          You must quote the sale number/item name, your invoice number and
          client number when making a payment. All payments sent by post must be
          sent to the address indicated on the invoice. For more information,
          please contact us at +1 954-288-6810 or email Monte@RightOfTheDot.com.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;&nbsp;
        <span class="c1">
          Buyer will not own the Lot and ownership of the Lot will not pass to
          Buyer until we have received full and clear payment of the Purchase
          Price, even in circumstances where we have inadvertently released the
          Lot to Buyer.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*------------------------------------------------------------------------------*/}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 11. &nbsp; TRANSFERRING RISK TO YOU</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          The risk in and responsibility for the Lot will transfer to you from
          whichever is the earlier of the following:
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;&nbsp;
            <span class="c1">When you collect the Lot; or</span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;&nbsp;
            <span class="c1">
              At the end of the 30th day following the date of the auction or,
              if earlier, the date the Lot is taken into care by a third party
              unless we have agreed otherwise with you.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*------------------------------------------------------------------------------*/}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong>12. &nbsp; DEFAULT – WHAT HAPPENS IF YOU DO NOT PAY</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          If Buyer (or Seller in cases where, under this agreement, the Seller
          bears the responsibility to pay) fails to pay us the Purchase Price in
          full by the due date, in its discretion ROTD is entitled to do one or
          more of the following (as well as enforce our rights as stated herein
          and any other rights or remedies we have at law or equity):
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;&nbsp;
            <span class="c1">
              We can cancel the sale of the Lot. If we do this, we may sell the
              Lot again, publicly or privately, on such terms as we think
              necessary or appropriate, in which case Buyer is responsible for
              any shortfall between the Purchase Price and the proceeds from the
              resale;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;&nbsp;
            <span class="c1">
              We can charge interest from the due date at a rate of up to 1.34%
              per month on the Purchase Price;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              We can pay Seller the amount due to Seller out of the net proceeds
              received from Buyer and initiate an action against Buyer for other
              amounts due;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iv.&nbsp;&nbsp;
            <span class="c1">
              We can institute legal proceedings to recover the Purchase Price
              together with other losses, interest as set forth in this
              agreement, and all reasonable legal fees and costs of suit
              (including expert witness costs) to the extent permitted by law;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            v.&nbsp;&nbsp;
            <span class="c1">
              We can take what you owe us from any amounts which we may owe you
              (including any of your sales, deposits, or other partial payment
              which you have paid to us);
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            vi.&nbsp;&nbsp;
            <span class="c1">
              We can, at our option, reveal your identity and contact details to
              the Seller;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            vii.&nbsp;&nbsp;
            <span class="c1">
              We can reject at any future auction any bids made by or on behalf
              of Buyer or demand a deposit from the Buyer before accepting any
              bids;
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            viii.&nbsp;&nbsp;
            <span class="c1">
              we can exercise all the rights and remedies of a person holding
              security over any property in our possession owned by you, whether
              by way of pledge, security interest or in any other way as
              permitted by the law of the place where such property is located.
              You will be deemed to have granted such security to us and we may
              retain such property as collateral security for your obligations
              to us; and
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ix.&nbsp;&nbsp;
            <span class="c1">
              we can take any other action we see necessary or appropriate and
              permitted by relevant law.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          If you owe money to us or to another ROTD partner, we can use any
          amount you do pay, including any deposit or other partial payment you
          have made to us or our Partners, or which we owe you, to pay off any
          amount you owe to us or another ROTD Partner for any transaction.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;&nbsp;
        <span class="c1">
          If we obtain a judgment against you for any amount of money, you agree
          that you will transfer to us any assets you own that we deem to be
          sufficient when sold to cover the cost of the amount owed. We will
          then sell the assets and reimburse you the overage, if any. This shall
          include domain names and any other fungible goods. If you do not at
          the time voluntarily transfer the assets to us for sale, then you
          hereby provide us with power of attorney for the purpose of signing
          your name and transferring the assets.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;&nbsp;
        <span class="c1">
          If you have breached this agreement in any way and we reasonably
          believe that you were acting on behalf of an undisclosed third party,
          you agree to assign us privity of contract with said third party, as
          if that party had agreed to this contract.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e.&nbsp;&nbsp;
        <span class="c1">
          If a Seller submits a property (domain name, or other asset) in
          error—such as by way of a misspelling, incorrect extension, a property
          which cannot be transferred or is collateralized, a crypto asset on a
          different block chain, or other error that makes a transaction
          impossible to complete—such submission is a breach of this agreement.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;&nbsp;
        <span class="c1">
          Failure to comply with the terms of this agreement as directed by ROTD
          is breach of this agreement.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        g.&nbsp;&nbsp;
        <span class="c1">
          If ROTD cancels an auction prior to sale because of any breach of this
          agreement, ROTD’s damages shall be 25 percent of the reserve price of
          the Lot, in addition to any other relief to which ROTD may be entitled
          by law or under this agreement.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        h.&nbsp;&nbsp;
        <span class="c1">
          If the Arbitrator finds that more than one party is responsible to any
          degree (i.e., even if one party is responsible for 1 percent of ROTD’s
          damages and the other party is responsible for the balance of the
          damages) then all responsible parties shall be jointly and severally
          liable for all damages.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*------------------------------------------------------------------------------*/}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 13. &nbsp;OTHER TERMS</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          <strong> Cancellation. </strong> In addition to the other rights of
          cancellation contained in this agreement, we can cancel a sale of a
          Lot if:
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;&nbsp;
            <span class="c1">any of your warranties are not correct;</span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;&nbsp;
            <span class="c1">
              we reasonably believe that completing the transaction is, or may
              be, unlawful; or
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              we reasonably believe that the sale places us or the Seller under
              any liability to anyone else or may damage our reputation.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          <strong> Recordings. </strong>We may videotape and record proceedings
          at any auction whether online or in person no matter what state or
          country you are in at the time. We will keep any personal information
          confidential, except to the extent disclosure is required by law.
          However, we may, through this process, use or share these recordings
          with another ROTD Partners and marketing partners to analyze our
          customers and to help us to tailor our services for Buyers. If you do
          not want to be videotaped, you must notify us thirty (30) days prior
          to the auction and make arrangements to make a telephone or written
          bid or bid with ROTD instead. Unless we agree otherwise in writing,
          you may not videotape or record proceedings at any auction.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;&nbsp;
        <span class="c1">
          <strong> Copyright.</strong> We own the copyright in all images,
          illustrations, and written material produced by or for us relating to
          a Lot (including the contents of our catalogues unless otherwise noted
          in the catalogue). You cannot use them without our prior written
          permission. We do not offer any guarantee that you will gain any
          copyright or other reproduction rights to the Lot.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;&nbsp;
        <span class="c1">
          <strong> Severability.</strong> If a court finds that any part of this
          agreement is not valid or is illegal or impossible to enforce, that
          part of the agreement will be treated as being deleted and the rest of
          this agreement will remain in force.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e.&nbsp;&nbsp;
        <span class="c1">
          <strong> Transfers.</strong> You may not grant a security over or
          transfer your rights or responsibilities under this agreement unless
          we have given our written permission. This agreement will be binding
          on your heirs, successors, assigns, or estate and anyone who takes
          over your rights and responsibilities.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;&nbsp;
        <span class="c1">
          <strong> Translations.</strong> We are under no obligation to provide
          translations of this agreement. If we have provided a translation of
          this agreement, this version of this agreement shall be the version by
          which we shall determine to exercise any discretion we have under this
          agreement, and shall also be the version by which an arbitrator or
          other tribunal shall determine the rights of any parties to a
          post-auction dispute.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        g.&nbsp;&nbsp;
        <span class="c1">
          <strong> Personal Information.</strong> We will hold and process your
          personal information and may pass it to another ROTD Partner for use
          as described in, and in line with, our privacy notice at&nbsp;
          <a href="https://rotd.com/">https://rotd.com/</a> if you are a
          resident of California, The California Consumer Privacy Act will
          apply.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        h.&nbsp;&nbsp;
        <span class="c1">
          <strong> Waiver.</strong> No failure or delay to exercise any right or
          remedy provided under these Terms & Conditions shall constitute a
          waiver of that or any other right or remedy, nor shall it prevent or
          restrict the further exercise of that or any other right or remedy. No
          single or partial exercise of such right or remedy shall prevent or
          restrict the further exercise of that or any other right or remedy.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        i.&nbsp;&nbsp;
        <span class="c1">
          <strong>Governing Law and Dispute Resolution.</strong>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            <span class="c1">PLEASE READ THIS SECTION CAREFULLY.</span>
          </p>

          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            i.&nbsp;&nbsp;&nbsp;&nbsp;
            <span class="c1">
              This agreement, and any non-contractual obligations arising out of
              or in connection with this agreement, or any other rights you may
              have relating to the sale or purchase of a Lot, will be governed
              by the laws of the state of Florida.
            </span>
          </p>

          <p class="c0 f-c">
            ii.&nbsp;&nbsp;&nbsp;
            <span class="c1">
              Prior to initiating any legal proceeding regarding claims arising
              from this agreement or the sale or purchase of a Lot, one or both
              of the parties shall initiate mediation before a single mediator
              in the state of Florida. If mediation is not accepted within ten
              business days of submission, or the dispute is not settled by
              mediation within 45 calendar days from the date of submission to
              mediation, then the dispute shall be submitted either to (i) JAMS
              or its successor, for final and binding arbitration in accordance
              with its Comprehensive Arbitration Rules and Procedures or, if the
              Dispute involves a non-U.S. party, the JAMS International
              Arbitration Rules, or (ii) the American Arbitration Association
              (“AAA”), in accordance with the Consumer Arbitration Rules of the
              AAA then in effect, except as modified by this Section . Service
              of Process shall be effectuated via email though physical service
              shall also be accepted. The seat of the arbitration shall be
              exclusively Florida and the arbitration shall be conducted by
              either onr or three arbitrators, who shall be appointed within 30
              days after the initiation of the arbitration. The language used in
              the arbitral proceedings shall be English. The arbitrator shall
              order the production of documents only upon a showing that such
              documents are relevant and material to the outcome of the Dispute.
              The arbitration shall be confidential, except to the extent
              necessary to enforce a judgment, where disclosure is required by
              law, or as to a final judgment with a reasoned opinion. The
              arbitration award shall be final and binding on all parties
              involved. Judgment upon the award may be entered by any court
              having jurisdiction thereof or having jurisdiction over the
              relevant party or its assets. This arbitration and any proceedings
              conducted hereunder shall be governed by Title 9 (Arbitration) of
              the United States Code and by the United Nations Convention on the
              Recognition and Enforcement of Foreign Arbitral Awards of June 10,
              1958.
            </span>
          </p>

          <p class="c0 c2">
            <span class="c1"></span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              ARBITRATION MEANS THAT YOU WAIVE YOUR RIGHT TO A JUDGE OR JURY IN
              A COURT PROCEEDING AND YOU GROUNDS FOR APPEAL ARE LIMITED.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>

          <p class="c0 f-c">
            iV.&nbsp;&nbsp;
            <span class="c1">
              <u> WAIVER OF CLASS ACTIONS AND CLASS ARBITRATIONS. </u> YOU AND
              ROTD AGREE THAT EACH PARTY MAY BRING DISPUTES AGAINST THE OTHER
              PARTY ONLY IN AN INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
              CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING,
              INCLUDING WITHOUT LIMITATION FEDERAL OR STATE CLASS ACTIONS, OR
              CLASS ARBITRATIONS. NEITHER YOU NOR ROTD WILL SEEK TO HAVE ANY
              DISPUTE HEARD AS A CLASS ACTION, PRIVATE ATTORNEY GENERAL ACTION,
              OR IN ANY OTHER PROCEEDING IN WHICH EITHER PARTY ACTS OR PROPOSES
              TO ACT IN A REPRESENTATIVE CAPACITY. NO ARBITRATION OR OTHER
              PROCEEDING WILL BE COMBINED WITH ANOTHER WITHOUT THE PRIOR WRITTEN
              CONSENT OF ALL PARTIES TO ALL AFFECTED ARBITRATIONS OR
              PROCEEDINGS.
            </span>
          </p>
          <p class="c0 c2">
            <span class="c1"></span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        j.&nbsp;&nbsp;
        <span class="c1">
          <strong>Reporting. </strong>
          Reports of the Final Sales Price and bidding may be reported on&nbsp;
          <a href="https://rotd.com/">https://rotd.com/</a>or our partner sites
          and also published in public media. We regret that we cannot agree to
          requests to remove these details&nbsp;
          <a href="https://rotd.com/">https://rotd.com/</a> or our partners or
          from public media.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*------------------------------------------------------------------------------*/}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0-center">
        <span class="c1">
          {" "}
          <strong> GLOSSARY </strong>{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Auctioneer: The individual auctioneer and/or ROTD.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Bidder: Any person or entity bidding for a particular Lot.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Blockchain: A distributed digital ledger of transactions maintained by
          a distributed peer-to-peer computer network that cryptographically
          validates transactions and records such transactions on the ledger.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Buyer: The person who wins the auction for a particular Lot.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Buyer’s Premium: The charge the Buyer pays us, which is some
          percentage of the Hammer Price.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Catalogue description: The description of a Lot in the catalogue for
          the auction, as amended by any notice.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Digital Asset: The digital work which the NFT identifies, and with
          which the NFT is associated.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Digital Wallet: A custodial or hosted software-based device, program,
          service, or hardware, that allows the owner to receive, store and
          transfer digital assets, including but not limited to,
          cryptocurrencies and NFTs, on a blockchain.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Digital Wallet Address: A unique alphanumeric string of characters
          that is associated with and represents the on-chain address for a
          digital wallet.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Estimate: The price range included in the catalogue or any notice
          within which we believe a Lot may sell. Low estimate means the lower
          figure in the range and high estimate means the higher figure. The mid
          estimate is the midpoint between the two.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Hammer Price: The amount of the highest bid the Auctioneer and/or ROTD
          accepts for the sale of a Lot. Also described throughout this
          agreement as “Final Sales Price.”
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Lot: An item to be offered at auction (or two or more items to be
          offered at auction as a group).
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Damages: Any special, consequential, incidental or indirect damages of
          any kind or any damages which fall within the meaning of ‘special’,
          ‘incidental’ or ‘consequential’ under relevant law.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Lot: A domain name, NFT, or other digital asset to be offered for sale
          at an auction (or two or more such items to be offered for sale as a
          group).
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          NFT (or non-fungible token): A unique digital certificate that
          identifies (including through a pointer to, or hash of, the digital
          asset(s)) and is associated with one or more digital assets, which is
          held and transferred on a blockchain and provides the owner with
          certain rights to the digital asset(s).
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          NFT Metadata: Information included within the NFT that includes at
          least: the name of the digital asset; a description of the digital
          asset; and the location of where the digital asset is stored or a hash
          of the digital asset itself (such as a content identifier).
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Ongoing NFT Payments: Any payments made in connection with the sale
          and any future payments, in each case that may be configured or
          programmed into the NFT smart contract, including any payments that
          are triggered when the NFT is transferred from one digital wallet
          address to another digital wallet address.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Owner: With respect to an NFT, the individual or entity that has the
          private key to the digital wallet that controls the ability to store
          or transfer the NFT.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Provenance: The ownership history of a Lot or digital asset, as
          applicable.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">Purchase Price: Has the meaning given herein.</span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Reserve: The amount below which we will not sell a Lot.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          RightOfTheDot, ROTD or ROTD Partners: ROTD and its subsidiaries and
          other companies within its corporate group and partners we work with.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Room Notice: A written notice posted next to the Lot in the saleroom
          and on https://rotd.com/ , which is also read to prospective telephone
          Bidders and notified to clients who have left commission bids, or an
          announcement made by the Auctioneer and/or ROTD either at the
          beginning of the sale, or before a particular Lot is auctioned.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Sale Particulars: The Lot information (the description of the Lot or
          Lots offered for sale together with other information on the sale
          website relating to a lot and the conduct of the sale); Lot notes;
          payment information; any on-screen notices displayed as part of a
          sale; the FAQs and any Special Conditions of Sale which apply to any
          Lot or group of Lots offered for sale at the same time.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Seller: The person placing a Lot for auction and for whom ROTD acts as
          agent.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Smart Contract: The computer code that governs the execution of an
          NFT.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Special Conditions of Sale: Any additional conditions which apply to a
          sale and which are set out in the Sale Particulars.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          Warranty: A statement or representation in which the person making it
          guarantees that the facts set out in it are correct.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      {/*------------------------------------------------------------------------------*/}
      <p class="c0-center">
        <span class="c1">
          <strong>APPENDIX A</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0-center">
        <span class="c2">
          <strong>
            ADDITIONAL CONDITIONS OF SALE – NON-FUNGIBLE TOKENS AS WELL AS{" "}
          </strong>
        </span>
      </p>
      <p class="c0-center">
        <span class="c2">
          <strong>ALL OTHER TYPES OF SALE</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          The general Terms & Conditions set forth above apply to the sale of
          Non-Fungible Tokens (“NFTs”) and other types of sales outside of the
          auction of domain names. However, the terms set forth below in
          Appendix A apply in addition to those general Terms & Conditions,
          specifically to the sale of such items. To the extent there is a
          conflict between the provisions of Appendix A and the general Terms &
          Conditions, the provisions of Appendix A shall control.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 1 . &nbsp; THE SELLER</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          For the purposes of the auction of a non-fungible token (“NFT”), the
          Seller is the party who owns the Lot at the time the sale is
          completed. In most circumstances, the Seller’s identity will not be
          disclosed by ROTD. ROTD will sell the Lot as the agent of the Seller.
          This means that ROTD is providing services to the Seller and that ROTD
          is concluding the contract for the sale of the Lot on behalf of the
          Seller.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 2 . &nbsp;BEFORE THE SALE</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          <strong> Description of Lots.</strong> Our description of a Lot may
          specify certain information, including: the smart contract address;
          the NFT Token ID: the blockchain on which the NFT is stored; the
          server or network on which the digital asset is stored; and the NFT
          metadata. You should review and confirm this information. We do not
          carry out any in-depth technical analysis of this information nor do
          we warrant its accuracy or completeness.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          <strong> Estimates.</strong>
          Estimates are based on the scarcity, quality and provenance of the
          Lot, on whether the NFT includes any ongoing NFT payments to any party
          including the Seller, and on prices recently paid at auction for
          similar property. Estimates can change. A Bidder should conduct its
          own investigation.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c0">
        <span>
          {" "}
          <strong> 3.&nbsp;&nbsp;</strong>
        </span>
        <span class="c1">
          <strong> Digital Wallet Requirements.</strong>
          Certain auctions may requirf-ce that if you bid in a cryptocurrency
          approved by ROTD, you have a digital wallet with a provider that we
          specify. If you do not have such a digital wallet, we may refuse to
          register you to bid or permit your participation in the sale and if
          you make a successful bid, we will have the right, in our sole
          discretion, to cancel the contract for sale between you and the
          Seller.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong> 4. &nbsp;TAXES AND ONGOING NFT PAYMENTS</strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          Taxes for the sale of each Lot at the responsibility of Seller and
          Buyer.
          <p class="c0 f-c">
            i.&nbsp;&nbsp;
            <span class="c1">
              YOU ARE RESPONSIBLE FOR ANY AND ALL TAXES. ROTD will NOT determine
              whether any applicable tax may be required to be collected from
              the successful Bidder in accordance with applicable law, including
              without limitation any sales or compensating use tax or their
              equivalent.
            </span>
          </p>
          <p class="c0 f-c">
            ii.&nbsp;&nbsp;
            <span class="c1">
              Depending upon applicable law, an NFT will generally be deemed to
              be delivered to the successful Bidder at the location of their tax
              domicile or, to the extent it can be determined, the location of
              the digital asset, unless otherwise agreed by ROTD, with the
              resulting tax (if any) determined by the applicable tax rates and
              rules in that jurisdiction. If you are the successful Bidder, you
              are responsible for all applicable tax including any VAT, sales or
              compensating use tax or equivalent tax wherever such taxes may
              arise on the Hammer Price and the Buyer’s Premium. VAT charges and
              refunds depend on your particular circumstances. It is your
              responsibility to report and ascertain and pay all taxes due. VAT
              may be payable on the Buyer’s Premium and, for some Lots, VAT may
              be payable on the Hammer Price. The successful Bidder is
              responsible for any applicable taxes including any sales or use
              tax or equivalent tax wherever such taxes may arise on the Hammer
              Price, the Buyer’s Premium, and/or any other charges related to
              the Lot.
            </span>
          </p>
          <p class="c0 f-c">
            iii.&nbsp;&nbsp;
            <span class="c1">
              In the event that your payment is made in cryptocurrency, you may
              be responsible for any income or other tax arising from your
              disposition of such cryptocurrency. If ROTD delivers the Lot to an
              agent in Florida that you have hired, Florida law considers the
              Lot delivered to the successful Bidder in Florida and Florida
              sales tax may be imposed regardless of your tax domicile. In this
              circumstance, Florida sales tax will apply to the Lot even if ROTD
              subsequently delivers the Lot to a successful Bidder with a tax
              domicile outside of Florida. Successful Bidders claiming an
              exemption from sales tax must provide appropriate documentation to
              ROTD upon request prior to the release of the Lot or within 90
              days after the sale, whichever is earlier.
            </span>
          </p>
          <p class="c0 f-c">
            iv.&nbsp;&nbsp;
            <span class="c1">
              For deliveries to Buyers in those U.S. states for which ROTD is
              not required to collect sales tax, a successful Bidder may have a
              use or similar tax obligation. ROTD recommends you consult your
              own independent tax advisor with any questions.
            </span>
          </p>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          <strong> Ongoing NFT Payments. </strong> You alone are responsible for
          determining whether there are any ongoing NFT payments, including any
          automated fees, commissions or royalties, in the NFT smart contract,
          which may be due by you to the Seller or other third party upon a
          subsequent transfer of the Lot by you to a third party, and by
          purchasers of the NFT going forward. ROTD is not responsible to you
          for any reason in connection with any ongoing NFT payments, including
          for making any disclosures to you in connection therewith. Ongoing NFT
          payments that are triggered by the sale itself are deducted from the
          proceeds of sale.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0">
        <span class="c1">
          <strong>
            {" "}
            5. &nbsp;Buyer’s Warranties and Representations for NFTs
          </strong>
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        a.&nbsp;&nbsp;
        <span class="c1">
          You acknowledge that your purchase of the Lot means you have full
          ownership rights in the NFT itself, including the right to store,
          sell, and transfer your NFT. Your purchase of the Lot does not provide
          any rights, express or implied, (including, without limitation, any
          copyrights or other intellectual property rights in and to) the
          digital asset underlying the NFT other than the right to use, copy,
          and display the digital asset for your own personal, non-commercial
          use or in connection with a proposed sale or transfer of the NFT and
          any other right expressly contained in these Conditions of Sale. For
          the avoidance of doubt, you do not have the right to distribute, or
          otherwise commercialize the digital asset without the prior
          authorization of the Seller or the party(ies) that holds such rights.
          Your rights and interest in the digital asset or NFT provided by these
          Terms & Conditions will immediately terminate upon any subsequent
          sale, transfer, dispossession, burning or other relinquishment of the
          NFT.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        b.&nbsp;&nbsp;
        <span class="c1">
          You understand and accept that NFTs are minted by third parties
          unaffiliated with ROTD, and not by ROTD itself or on ROTD behalf.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        c.&nbsp;&nbsp;
        <span class="c1">
          You acknowledge and agree that there are risks associated with
          purchasing, holding, and using NFTs. By purchasing, holding and using
          an NFT, you expressly acknowledge and assume all risks including, but
          not limited to: Seller or Buyer user error such as forgotten
          passwords, mistyped addresses, incorrectly constructed transactions,
          incorrectly programmed NFTs, mining attacks, cybersecurity attacks,
          weaknesses in ROTD security; blockchain malfunctions or other
          technical errors, telecommunications failure, malicious software,
          unfavorable regulatory determinations or actions in one or more
          jurisdictions (including with respect to NFTs or cryptocurrencies),
          taxation of NFTs or cryptocurrencies, personal information disclosure,
          uninsured losses, unanticipated risks, volatility risks, server
          failure or data loss, corrupted or otherwise inaccessible digital
          wallets, unauthorized access to applications , inability to access or
          transfer the NFT, inability to access or display the digital asset,
          risks arising from third-party providers, including third-party
          providers that may mint the NFT and/or store the digital asset, and
          any unauthorized third party activities, including without limitation
          the introduction use of viruses or other malicious code, the use of
          phishing, sybil attacks, 51% attacks, brute forcing, changes to the
          protocol rules of the blockchain (i.e., “forks”), or other means of
          attack that affect, in any way, the NFT or digital asset. If you have
          any questions regarding these risks, please contact us at
          info@rightofthedot.com.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        d.&nbsp;&nbsp;
        <span class="c1">
          You acknowledge that you have a sufficient understanding of the
          functionality, usage, storage, transmission mechanisms and other
          material characteristics of, blockchain technology, NFTs, digital
          assets, digital wallets, and cryptocurrencies to understand these
          Conditions of Sale and to appreciate the risks and implications of
          purchasing NFTs. You acknowledge that you have obtained sufficient
          information to make an informed decision to purchase an NFT, including
          reviewing the code of the smart contract, the NFT metadata, and the
          NFT, as well as the storage system used for the digital asset and
          fully understand and accept the functions of the same.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        e.&nbsp;&nbsp;
        <span class="c1">
          You acknowledge that we do not guarantee that you will be able to
          receive, access or view the Lot and/or digital asset. We do not
          provide any application, or other service to enable you to view,
          receive, access or view the Lot and/or digital asset, and that it is
          your responsibility to procure a digital wallet or other means to
          allow us or the Seller to transfer the Lot to you and for you to
          access or view the digital asset upon such transfer.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c">
        f.&nbsp;&nbsp;
        <span class="c1">
          You acknowledge and represent that there is substantial uncertainty as
          to the characterization of NFTs and other digital assets under
          applicable law. You acknowledge that your purchase of an NFT complies
          with applicable laws and regulations in your jurisdiction.{" "}
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c0">
        <span> 6.&nbsp;&nbsp;</span>
        <span class="c1">
          <strong>Transfer of Risk to Buyer. </strong> The risk in and
          responsibility for the Lot will transfer to you once the Lot is
          received in the digital wallet that you have specified, which time may
          be determined at ROTD’s discretion, including through observation of
          the transfer on the blockchain on which the NFT is stored.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c0">
        <span> 7.&nbsp;&nbsp;</span>
        <span class="c1">
          <strong>Security Measures. </strong> You are responsible for
          implementing reasonable measures for securing the digital wallet or
          other storage mechanism you use to receive and hold the NFT, including
          any requisite private key(s) or other credentials necessary to access
          such storage mechanism(s), and further you specifically acknowledge
          that the risk of acquiring the NFT from the Seller, transferring the
          NFT to others, and holding or using the NFT or your digital wallet
          rests entirely with you. If your private key(s) or other access
          credentials are lost, you may lose access to your NFT. We are not
          responsible for any such losses, including, but not limited to, losses
          arising from third-party service providers.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c0">
        <span> 8.&nbsp;&nbsp;</span>
        <span class="c1">
          <strong>Future Inaccessibility. </strong> We are not responsible if
          the digital asset becomes inaccessible to you for any reason, or for
          any modifications or changes to the digital asset, including the
          digital asset being deleted. You acknowledge that the artist or any
          third party could make additional copies of, and distribute, the
          digital asset, and could sell or otherwise assign the copyright or
          other intellectual property rights or economic rights in the digital
          asset.
        </span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 f-c0">
        <span> 9.&nbsp;&nbsp;</span>
        <span class="c1">
          <strong>Future Use. </strong> We are not responsible for any uses you
          make of the NFT or for any future transfers you make of the NFT.
        </span>
      </p>
      {/*------------------------------------------------------------------------------*/}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>{" "}
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
      <p class="c0 c2">
        <span class="c1"></span>
      </p>
    </div>
  );
}

export default LoginCover;
