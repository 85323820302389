import AnalyticsTwoToneIcon from "@material-ui/icons/AnalyticsTwoTone";
import AccountBalanceTwoToneIcon from "@material-ui/icons/AccountBalanceTwoTone";
import StoreTwoToneIcon from "@material-ui/icons/StoreTwoTone";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import KitchenTwoToneIcon from "@material-ui/icons/KitchenTwoTone";
import HealthAndSafetyTwoToneIcon from "@material-ui/icons/HealthAndSafetyTwoTone";
import ContactSupportTwoToneIcon from "@material-ui/icons/ContactSupportTwoTone";
import LocalLibraryTwoToneIcon from "@material-ui/icons/LocalLibraryTwoTone";
import DnsTwoToneIcon from "@material-ui/icons/DnsTwoTone";
import TaskAltTwoToneIcon from "@material-ui/icons/TaskAltTwoTone";
import EventNoteTwoToneIcon from "@material-ui/icons/EventNoteTwoTone";
import DocumentScannerTwoToneIcon from "@material-ui/icons/DocumentScannerTwoTone";
import WorkTwoToneIcon from "@material-ui/icons/WorkTwoTone";
import MarkunreadMailboxTwoToneIcon from "@material-ui/icons/MarkunreadMailboxTwoTone";
import QuestionAnswerTwoToneIcon from "@material-ui/icons/QuestionAnswerTwoTone";
import DashboardCustomizeTwoToneIcon from "@material-ui/icons/DashboardCustomizeTwoTone";
import AssignmentIndTwoToneIcon from "@material-ui/icons/AssignmentIndTwoTone";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import WbIncandescentTwoToneIcon from "@material-ui/icons/WbIncandescentTwoTone";
import DesignServicesTwoToneIcon from "@material-ui/icons/DesignServicesTwoTone";
import SupportTwoToneIcon from "@material-ui/icons/SupportTwoTone";
import ReceiptTwoToneIcon from "@material-ui/icons/ReceiptTwoTone";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import DomainIcon from "@material-ui/icons/Domain";

const menuItems = [
  {
    heading: "Dashboards",
    items: [
      {
        name: "Overview",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/preview/dashboards/crypto",
      },
      /*
      {
        name: "Current Auctions",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/current-auctions",
      },
      */
/*
      {
        name: "My Auctions",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/preview/my-auctions/",
        items: [
          {
            name: "I Take Part in Auctions",
            icon: AssignmentIndTwoToneIcon,
            link: "/preview/my-auctions/part-auctions",
          },
          {
            name: "I Watch for Auctions",
            icon: AssignmentIndTwoToneIcon,
            link: "/preview/my-auctions/watch-auctions",
          },
        ],
      },
*/
    ],
  },
  /*
  {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/preview/management/users',
        items: [
          {
            name: 'List All',
            link: '/preview/management/users/list'
          }
        ]
      },
      {
        name: 'Submissions',
        icon: AccountTreeTwoToneIcon,
        link: '/preview/management/projects/list'
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: '/preview/management/invoices',
        items: [
          {
            name: 'List All',
            link: '/preview/management/invoices/list'
          }
        ]
      }
    ]
  }*/
];

export default menuItems;
