import { useTranslation } from "react-i18next";
import {
  Box,
  Grid,
  Container,
  Card,
  Avatar,
  Typography,
  Button,
  Tooltip,
} from "@material-ui/core";
import CheckTwoToneIcon from "@material-ui/icons/CheckTwoTone";
import { experimentalStyled } from "@material-ui/core/styles";
import deFlag from "country-flag-icons/3x2/DE.svg";
import usFlag from "country-flag-icons/3x2/US.svg";
import esFlag from "country-flag-icons/3x2/ES.svg";
import frFlag from "country-flag-icons/3x2/FR.svg";
import cnFlag from "country-flag-icons/3x2/CN.svg";
import aeFlag from "country-flag-icons/3x2/AE.svg";

const AvatarSuccess = experimentalStyled(Avatar)(
  ({ theme }) => `
    background: ${theme.colors.success.light};
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
`
);

const BoxRtl = experimentalStyled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.white[100]};
`
);

const ImageWrapper = experimentalStyled("img")(
  ({ theme }) => `
        margin-right: ${theme.spacing(1)};
        width: 44px;
`
);

const CardImg = experimentalStyled(Card)(
  ({ theme }) => `
    position: absolute;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(["border"])};

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`
);

const TypographyH1 = experimentalStyled(Typography)(
  ({ theme }) => `
    color: ${theme.colors.primary.main};
    font-size: ${theme.typography.pxToRem(36)};
`
);

const TypographyH1Primary = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(36)};
`
);

const TypographyH2 = experimentalStyled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);

const BoxHighlights = experimentalStyled(Box)(
  () => `
    position: relative;
    z-index: 5;
`
);

const ScreenshotWrapper = experimentalStyled(Card)(
  ({ theme }) => `
    perspective: 700px;
    display: flex;
    overflow: visible;
    background: ${theme.colors.alpha.black[100]};
`
);

const Screenshot = experimentalStyled("img")(
  ({ theme }) => `
    width: 100%;
    height: auto;
    transform: rotateY(-35deg);
    border-radius: ${theme.general.borderRadius};
`
);

const icons = {
  Auth0: "/static/images/logo/auth0.svg",
  FirebaseAuth: "/static/images/logo/firebase.svg",
  JWT: "/static/images/logo/jwt.svg",
};

function Highlights() {
  const { t } = useTranslation();

  return (
    <BoxHighlights id="key-features">
      <Container maxWidth="lg" sx={{ py: { xs: 5, md: 10 } }}>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            mb: { xs: 5, md: 10 },
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          <Box display="flex" alignItems="center" p={3} mx={3}>
            <TypographyH1 variant="h1" sx={{ pr: 1 }}>
              {/*1,000*/}
            </TypographyH1>
            <Typography variant="h4" color="text.secondary">
              {/*NFT's*/}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" p={3} mx={3}>
            <TypographyH1 variant="h1" sx={{ pr: 1 }}>
              {/*2,500*/}
            </TypographyH1>
            <Typography variant="h4" color="text.secondary">
              {/*domains*/}
            </Typography>
          </Box>
        </Box>
        <TypographyH1Primary textAlign="center" sx={{ mb: 2 }} variant="h1">
          {t("Digital Asset Auctions")}
        </TypographyH1Primary>
        <Container sx={{ mb: 6 }} maxWidth="sm">
          <TypographyH2
            sx={{ pb: 4 }}
            textAlign="center"
            variant="h4"
            color="text.secondary"
            fontWeight="normal"
          >
            {t(
              "Led by Domain Industry Pioneer Monte Cahn, RightOfTheDot is an accredited and licensed business & digital asset auction company, Internet consulting and advisement firm specializing in premium domain names, digital assets and NFT auctions, sales, brokerage in new and existing Top Level Domains and digital real estate. RightOfTheDot also specializes in Contention Resolution Services involving same or similar digital assets, board advisement, premium domain market positioning, sales and services. Monte was Founder and former CEO of Moniker.com, former President of SnapNames.com and former Sr. VP of DomainSponsor.com. Monte Cahn and RightOfTheDot have been involved in more than 1000 live and online auctions and $560 MM in domain names, digital real estate, and asset sales since 1995."
            )}
          </TypographyH2>
        </Container>
      </Container>
    </BoxHighlights>
  );
}

export default Highlights;
