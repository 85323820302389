import { useState } from "react";
import PropTypes from "prop-types";
import { experimentalStyled } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import Sidebar from "./Sidebar";
import Header from "./Header";
import useAuth from "src/hooks/useAuth";
import ContextDialog from "src/components/ContextDialog";
import TermsConditions from "src/content/pages/Status/TermsConditions/OnlyText";
import axios from "src/utils/axios";

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarLayout = ({ children }) => {
  const { user } = useAuth();
  const [open, setOpen] = useState(!user?.isReadTermAndCondition);
  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>{children}</MainContent>
      </MainWrapper>
      <ContextDialog
        open={open}
        setOpen={setOpen}
        onRemoveHandle={() => {
          setOpen(false);
          user.isReadTermAndCondition = true;
          axios.get("api/api/user/is-read").then();

        }}
        title={"Terms and Conditions"}
      >
        <h4 style={{ "text-align": "center", "font-style": "italic" }}>
          You Must Review All Terms and Conditions through the bottom of the
          page to Continue
        </h4>
        <TermsConditions />
      </ContextDialog>
    </>
  );
};

SidebarLayout.propTypes = {
  children: PropTypes.node,
};

export default SidebarLayout;
