import { Box, List, ListItem, ListItemText } from "@material-ui/core";
// import { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';

function HeaderMenu() {
  const { t } = useTranslation();
  const history = useHistory();
  // const ref = useRef(null);
  // const [isOpen, setOpen] = useState(false);

  // const handleOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <List
        sx={{ pl: { xs: 0, md: 2 } }}
        component={Box}
        display="flex"
        onClick={() => {
          history.push("/current-auctions");
        }}
      >
        <ListItem
          classes={{ root: "MuiListItem-indicators" }}
          button
          component="a"
        >
          <ListItemText
            primaryTypographyProps={{ noWrap: true }}
            primary={t("Current Auctions")}
          />
        </ListItem>
      </List>
    </>
  );
}

export default HeaderMenu;
