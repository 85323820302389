import AnalyticsTwoToneIcon from "@material-ui/icons/AnalyticsTwoTone";
import AccountBalanceTwoToneIcon from "@material-ui/icons/AccountBalanceTwoTone";
import StoreTwoToneIcon from "@material-ui/icons/StoreTwoTone";
import AccountBalanceWalletTwoToneIcon from "@material-ui/icons/AccountBalanceWalletTwoTone";
import MonetizationOnTwoToneIcon from "@material-ui/icons/MonetizationOnTwoTone";
import KitchenTwoToneIcon from "@material-ui/icons/KitchenTwoTone";
import HealthAndSafetyTwoToneIcon from "@material-ui/icons/HealthAndSafetyTwoTone";
import ContactSupportTwoToneIcon from "@material-ui/icons/ContactSupportTwoTone";
import LocalLibraryTwoToneIcon from "@material-ui/icons/LocalLibraryTwoTone";
import DnsTwoToneIcon from "@material-ui/icons/DnsTwoTone";
import TaskAltTwoToneIcon from "@material-ui/icons/TaskAltTwoTone";
import EventNoteTwoToneIcon from "@material-ui/icons/EventNoteTwoTone";
import DocumentScannerTwoToneIcon from "@material-ui/icons/DocumentScannerTwoTone";
import WorkTwoToneIcon from "@material-ui/icons/WorkTwoTone";
import MarkunreadMailboxTwoToneIcon from "@material-ui/icons/MarkunreadMailboxTwoTone";
import QuestionAnswerTwoToneIcon from "@material-ui/icons/QuestionAnswerTwoTone";
import DashboardCustomizeTwoToneIcon from "@material-ui/icons/DashboardCustomizeTwoTone";
import AssignmentIndTwoToneIcon from "@material-ui/icons/AssignmentIndTwoTone";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import StorefrontTwoToneIcon from "@material-ui/icons/StorefrontTwoTone";
import VpnKeyTwoToneIcon from "@material-ui/icons/VpnKeyTwoTone";
import ErrorTwoToneIcon from "@material-ui/icons/ErrorTwoTone";
import WbIncandescentTwoToneIcon from "@material-ui/icons/WbIncandescentTwoTone";
import DesignServicesTwoToneIcon from "@material-ui/icons/DesignServicesTwoTone";
import SupportTwoToneIcon from "@material-ui/icons/SupportTwoTone";
import ReceiptTwoToneIcon from "@material-ui/icons/ReceiptTwoTone";
import FiberManualRecordTwoToneIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import DomainIcon from '@material-ui/icons/Domain';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import InfoIcon from '@material-ui/icons/Info';
import EventIcon from '@material-ui/icons/Event';
import SettingsSuggestIcon from "@material-ui/icons/SettingsSuggest";

const menuItems = [
  {
    heading: "Dashboards",
    items: [
      {
        name: "Overview",
        icon: AccountBalanceWalletTwoToneIcon,
        link: "/admin/crypto",
      },
      /*
            {
              name: "Current Auctions",
              icon: AccountBalanceWalletTwoToneIcon,
              link: "/current-auctions",
              isShowAuctions: true
            },
            */
      /*
       {
         name: "My Auctions",
         icon: AccountBalanceWalletTwoToneIcon,
         link: "/admin/my-auctions/",
         isShowAuctions: true,
         items: [
           {
             name: "I Take Part in Auctions",
             icon: AssignmentIndTwoToneIcon,
             link: "/admin/my-auctions/part-auctions",
 
           },
           {
             name: "I Watch for Auctions",
             icon: AssignmentIndTwoToneIcon,
             link: "/admin/my-auctions/watch-auctions",
 
           },
         ]
       },
       */
      {
        name: "Admin Settings",
        icon: AccountTreeTwoToneIcon,
        link: "/admin/",
        items: [
          {
            name: "Users",
            icon: AssignmentIndTwoToneIcon,
            link: "/admin/users/list",

          },
          {
            name: "Domains",
            icon: DomainIcon,
            link: "/admin/domains/list",
          },
          {
            name: "NFT",
            icon: ViewQuiltIcon,
            link: "/admin/nft/list",
          },
          {
            name: "Other Assets",
            icon: ViewModuleIcon,
            link: "/admin/other/list",
          },
          {
            name: "Events",
            icon: EventIcon,
            link: "/admin/events/list",
          },
          {
            name: "Log",
            icon: InfoIcon,
            link: "/admin/log/list",
          },
          {
            name: "Admin Settings",
            icon: SettingsSuggestIcon,
            link: "/admin/admin-settings",
          },
          /*
          {
            name: "Invoices",
            icon: ReceiptTwoToneIcon,
            link: "/management/invoices",
            items: [
              {
                name: "List All",
                link: "/preview/management/invoices/list",
              },
            ],
          },*/
        ],
      },
    ],
  },
  /*
  {
    heading: 'Management',
    items: [
      {
        name: 'Users',
        icon: AssignmentIndTwoToneIcon,
        link: '/preview/management/users',
        items: [
          {
            name: 'List All',
            link: '/preview/management/users/list'
          }
        ]
      },
      {
        name: 'Submissions',
        icon: AccountTreeTwoToneIcon,
        link: '/preview/management/projects/list'
      },
      {
        name: 'Invoices',
        icon: ReceiptTwoToneIcon,
        link: '/preview/management/invoices',
        items: [
          {
            name: 'List All',
            link: '/preview/management/invoices/list'
          }
        ]
      }
    ]
  }*/
];

export default menuItems;
