import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, Typography, Grid } from "@material-ui/core";

export default function AlertDialog({
  open,
  setOpen,
  onRemoveHandle,
  title,
  showOk,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    onRemoveHandle();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              ml: -1,
            }}
          >
            <Grid
              container
              spacing={4}
              sx={{
                justifyContent: "flex-start",
                alignItems: "center",
                color: "#FFFFFF",
                flexDirection: "column",
              }}
            >
              <Grid item>
                <img
                  height={50}
                  alt="Google"
                  src="/static/images/logo/priorityHighIcon.svg"
                />
              </Grid>
              <Grid item>
                <Typography color="textPrimary" variant="h1">
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"></DialogContentText>
        </DialogContent>
        <DialogActions>
          {showOk ? (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleRemove}
              >
                Ok
              </Button>
            </>
          ) : (
            <>
              <Button
                color="secondary"
                variant="contained"
                onClick={handleRemove}
              >
                Yes
              </Button>
              <Button color="primary" variant="contained" onClick={handleClose}>
                No
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
