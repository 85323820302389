import { Box, Container, Hidden, Card, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import ContentWrapper from "src/components/ContentWrapper";
import { useTranslation } from "react-i18next";
import { experimentalStyled } from "@material-ui/core/styles";
import Logo from "src/components/Logo";
import Menu from "./Menu";
import MenuCurrentAuctions from "./Menu/CurrentAuctions";
import Hero from "./Hero";
import Highlights from "./Highlights";
import SettingsButton from "src/layouts/SidebarLayout/Header/Buttons/Settings";
import Footer from "src/components/Footer";
import useAuth from "src/hooks/useAuth";
import HeaderUserbox from "src/components/Userbox";

const HeaderWrapper = experimentalStyled(Card)(
  ({ theme }) => `
    width: 100%;
    border-radius: 0;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 6;
    top: 0;
    height: ${theme.spacing(10)};
`
);

const OverviewWrapper = experimentalStyled(Box)(
  ({ theme }) => `
    overflow: auto;
    flex: 1;
    overflow-x: hidden;
    margin-top: ${theme.spacing(10)};
`
);

function Overview() {
  const { t } = useTranslation();

  const { isAuthenticated, user } = useAuth();

  return (
    <OverviewWrapper>
      <ContentWrapper title="ROTD Auctions">
        <HeaderWrapper>
          <Container maxWidth="lg">
            <Box display="flex" alignItems="center">
              <Logo />

              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flex={1}
              >
                <Menu />
                {/* <MenuCurrentAuctions /> */}
                <Box>
                  {/*
                  <Hidden smDown>
                    <SettingsButton />

                  </Hidden>
                  */}

                  <Button
                    size="small"
                    component={RouterLink}
                    to={
                      user && user.isAdmin
                        ? "/admin/crypto"
                        : "/preview/dashboards/crypto"
                    }
                    variant="contained"
                  >
                    {t("Get Started")}
                  </Button>

                  {isAuthenticated ? <HeaderUserbox /> : ""}
                </Box>
              </Box>
            </Box>
          </Container>
        </HeaderWrapper>
        <Hero isAuthenticated={isAuthenticated} />
        <Highlights />
      </ContentWrapper>
      <Footer />
    </OverviewWrapper>
  );
}

export default Overview;
