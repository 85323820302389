import { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import Authenticated from "src/components/Authenticated";
 
import SidebarLayout from "src/layouts/SidebarLayout";
import SidebarLayoutAdmin from "src/layouts/SidebarLayoutAdmin";
 
import BaseLayout from "src/layouts/BaseLayout";

import Overview from "src/content/overview";
import SuspenseLoader from "src/components/SuspenseLoader";
import Guest from "src/components/Guest";
import AuthenticatedAdmin from "src/components/Authenticated";

export const renderRoutes = (routes) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    path: "/profile",
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: "/profile/update",
        component: lazy(() => import("src/content/Profile")),
      },
      {
        exact: true,
        path: "/profile/update-password",
        component: lazy(() => import("src/content/Profile/updatePassword")),
      },
    ],
  },
  {
    path: "/admin",
    guard: AuthenticatedAdmin,
    layout: SidebarLayoutAdmin,
    routes: [
      {
        exact: true,
        path: "/admin/users",
        component: () => <Redirect to="/admin/users/list" />,
      },
      {
        exact: true,
        path: "/admin",
        component: () => <Redirect to="/admin/users/list" />,
      },
      {
        exact: true,
        path: "/admin/crypto",
        component: lazy(() => import("src/content/dashboards/Crypto")),
      },
     
      {
        exact: true,
        path: "/admin/my-auctions/part-auctions",
        component: lazy(() => import("src/content/myauctions/PartAuctions")),
      },
      {
        exact: true,
        path: "/admin/my-auctions/watch-auctions",
        component: lazy(() => import("src/content/myauctions/WatchAuctions")),
      },
      {
        exact: true,
        path: "/admin/users/list",
        component: lazy(() => import("src/content/admin/Users")),
      },
 
      {
        exact: true,
        path: "/admin/domains",
        component: () => <Redirect to="/admin/domains/list" />,
      },

      {
        exact: true,
        path: "/admin/domains/list",
        component: lazy(() => import("src/content/admin/Domains")),
      },
      {
        exact: true,
        path: "/admin/nft",
        component: () => <Redirect to="/admin/domains/list" />,
      },

      {
        exact: true,
        path: "/admin/nft/list",
        component: lazy(() => import("src/content/admin/Nft")),
      },
      {
        exact: true,
        path: "/admin/other",
        component: () => <Redirect to="/admin/other/list" />,
      },
      {
        exact: true,
        path: "/admin/other/list",
        component: lazy(() => import("src/content/admin/Other")),
      },
      {
        exact: true,
        path: "/admin/events",
        component: () => <Redirect to="/admin/events/list" />,
      },
      {
        exact: true,
        path: "/admin/events/list",
        component: lazy(() => import("src/content/admin/Events")),
      },
      {
        exact: true,
        path: "/admin/log",
        component: () => <Redirect to="/admin/domains/list" />,
      },
      {
        exact: true,
        path: "/admin/log/list",
        component: lazy(() => import("src/content/admin/Log")),
      },
      {
        exact: true,
        path: "/admin/update",
        component: lazy(() => import("src/content/Profile")),
      },
      {
        exact: true,
        path: "/admin/update-password",
        component: lazy(() => import("src/content/Profile/updatePassword")),
      },
      {
        exact: true,
        path: "/admin/admin-settings",
        component: lazy(() => import("src/content/admin/AdminSettings")),
      },
      
    ],
  },
  {
    path: "/preview",
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
 
      {
        exact: true,
        path: "/preview/dashboards/crypto",
        component: lazy(() => import("src/content/dashboards/Crypto")),
      },
 
      {
        exact: true,
        path: "/preview/my-auctions",
        component: () => <Redirect to="/my-auctions/part-auctions" />,
      },
      {
        exact: true,
        path: "/preview/my-auctions/part-auctions",
        component: lazy(() => import("src/content/myauctions/PartAuctions")),
      },
      {
        exact: true,
        path: "/preview/my-auctions/watch-auctions",
        component: lazy(() => import("src/content/myauctions/WatchAuctions")),
      },
   
      {
        component: () => <Redirect to="/status/404" />,
      },
    ],
  },
 
  {
    exact: true,
    path: "/overview",
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    guard: Guest,
    path: "/terms-and-conditions",
    component: lazy(() => import("src/content/pages/Status/TermsConditions")),
  },
  {
    exact: true,
    guard: Guest,
    path: "/login",
    component: lazy(() => import("src/content/pages/Auth/Login/Cover")),
  },
  {
    exact: true,
    guard: Guest,
    path: "/register",
    component: lazy(() => import("src/content/pages/Auth/Register/Cover")),
  },
 
  {
    exact: true,
    path: "/login-cover",
    component: lazy(() => import("src/content/pages/Auth/Login/Cover")),
  },
 
  {
    exact: true,
    path: "/register-cover",
    component: lazy(() => import("src/content/pages/Auth/Register/Cover")),
  },
  {
    exact: true,
    path: "/register-wizard",
    component: lazy(() => import("src/content/pages/Auth/Register/Wizard")),
  },
  {
    exact: true,
    path: "/current-auctions/:idAuctions",
    // component: lazy(() => import("src/content/overview/CurrentAuctionsItem")),
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/current-auctions",
    // component: lazy(() => import("src/content/overview/CurrentAuctions")),
    component: () => <Redirect to="/" />,
  },
  {
    exact: true,
    path: "/recover-password",
    component: lazy(() => import("src/content/pages/Auth/RecoverPassword")),
  },
  {
    exact: true,
    path: "/change-password",
    component: lazy(() => import("src/content/pages/Auth/ChangePassword")),
  },
  {
    exact: true,
    path: "/activate-user",
    component: lazy(() => import("src/content/pages/Auth/ActivateUser")),
  },
  {
    exact: true,
    path: "/status/404",
    component: lazy(() => import("src/content/pages/Status/Status404")),
  },
  {
    exact: true,
    path: "/status/500",
    component: lazy(() => import("src/content/pages/Status/Status500")),
  },
 
  {
    path: "*",
    layout: BaseLayout,
    routes: [
      {
        exact: true,
        path: "/",
        component: Overview,
      },
      {
        component: () => <Redirect to="/" />,
      },
    ],
  },
];

export default routes;
