import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "src/hooks/useAuth";
import { useLocation } from "react-router-dom";

const Guest = ({ children }) => {
  const { isAuthenticated, user } = useAuth();
  const location = useLocation();

  console.log(location.pathname);

  if (`${location.pathname}`.toLocaleLowerCase() === "/terms-and-conditions")
    return <>{children}</>;

  if (isAuthenticated && user && user.isAdmin) {
    return <Redirect to="/admin/crypto" />;
  } else if (isAuthenticated) {
    return <Redirect to="/preview/dashboards/crypto" />;
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node,
};

export default Guest;
